import { setErrorMessage } from "./glolbalSlice";

export const createGreeting = (firstName, lastName) => {
  let addEnding = "!";
  if (lastName) {
    addEnding = " " + lastName + "!";
  }
  return "Welcome to Sorcie, " + firstName + addEnding;
};

export const dispatchError = (error, api) => {
  const response = error.response;
  let message = "";
  if (response)
    if (Array.isArray(response.data.detail))
      message = response.data.detail[0].msg;
    else message = response.data.detail;
  else message = error.message;
  api.dispatch(setErrorMessage(message));
  return api.rejectWithValue(message);
};
