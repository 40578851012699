import css from "./Agreement.module.css";

export const Agreement = () => {
  return (
    <section>
      <div className="container">
        <div className={css.agreementContainer}>
          <h1 className={css.title}>Ліцензійний договір </h1>
          <p>«30» травня 2024 року</p>
          <br />
          <br />
          <p>
            <b>ФОП Бойко Олександр Русланович</b> (Код ЄДРПОУ 3691312690),
            зареєстрований належним чином та діє згідно з законодавством
            України, іменований в подальшому «Ліцензіар», керуючись
            законодавством України, зокрема , статтями 633, 634, 641-647
            Цивільного кодексу України, пропонує будь-якій фізичній / юридичній
            особі / підприємцю, іменованому надалі «Ліцензіат», разом іменовані
            надалі «Сторони», а окремо, – "Сторона", укласти цей Ліцензійний
            договір, викладений в стандартній і незмінній для всіх форм у
            вигляді публічної оферти (пропозиції укласти договір), шляхом
            прийняття в цілому і безумовно наступних умов:
          </p>
          <p>Терміни, що використовується у договорі і їх значення: </p>
          <ol className={css.ol} type="a">
            <li className={css.li}>
              <b>Програма</b> — програмне забезпечення «Sorcie», призначене для
              автоматизації процесів пошуку та відбору кандидатів через
              різноманітні онлайн-платформи з вакансіями. Виняткові права на
              Програму належать Ліцензіару. Передача виключних прав (повністю
              або в частині) не є предметом цього Договору. Детальний опис
              функціональності Програми доступний Ліцензіату на офіційному сайті
              Ліцензіара за адресою{" "}
              <a href="https://sorcie.tech">sorcie.tech</a>.
            </li>
            <li className={css.li}>
              <b>Проста (невиключна) ліцензія</b> — невиключне право на
              використання Ліцензіатом Програми на території, зазначеної в п.
              1.3. цього Договору, для власного споживання з обмеженнями згідно
              з цим Договором.
            </li>
            <li className={css.li}>
              <b>SaaS-версія</b> — спосіб надання доступу до Програми,
              розташованої на Сервері Ліцензіара, через інтернет-браузер з
              використанням Логіна і Пароля, без установки дистрибутива Програми
              на ЕОМ Ліцензіата.
            </li>
            <li className={css.li}>
              <b>Тарифи</b> — прайс-лист Ліцензіара, розташований в мережі
              Інтернет за адресою{" "}
              <a href="https://sorcie.tech/pricing">sorcie.tech/pricing</a>.
            </li>
            <li className={css.li}>
              <b>Сайт Ліцензіара</b> — сайт, розташований в мережі Інтернет за
              адресою <a href="https://sorcie.tech">sorcie.tech</a>.
            </li>
            <li className={css.li}>
              <b>Обліковий запис</b> — це унікальний ідентифікатор користувача в
              системі Sorcie, який використовується для реєстрації, авторизації
              та зберігання інформації, пов'язаної з діяльністю Ліцензіата в
              межах сервісу. Це включає персональні дані, вказані під час
              реєстрації, налаштування використання Програми, а також будь-які
              дані, які Ліцензіат вводить або зберігає в Програмі з метою
              оптимізації процесу пошуку кандидатів.
            </li>
            <li className={css.li}>
              <b>Логін і Пароль</b> — унікальний набір символів, який
              створюється Ліцензіатом при проходженні реєстрації, призначений
              для доступу до Програми.
            </li>
            <li className={css.li}>
              <b>Обліковий період</b> — період часу, не менше одного
              календарного місяця, на який оплачена Ліцензія.
            </li>
            <li className={css.li}>
              <b>Сервер</b> — віртуальна машина на хмарному сервісі, призначена
              для розгортання та функціонування Програми Sorcie. Забезпечує
              обчислювальні ресурси та середовище для оптимальної роботи
              Програми.
            </li>
            <li className={css.li}>
              <b>Період відстрочки</b> — період очікування надходження оплати
              від Ліцензіата, тривалістю від 1 до 7 календарних днів, протягом
              якого Ліцензіар надає Ліцензіату право користування Програмою в
              рахунок майбутньої оплати.
            </li>
            <li className={css.li}>
              <b>Повторюваний платіж</b> — платіж, здійснений в автоматичному
              режимі з використанням збереженого способу оплати.
            </li>
          </ol>
          <ol className={css.olNested} start="1">
            <li className={css.liNested}>
              Предмет договору
              <ol className={`${css.olNested} ${css.nested}`}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Згідно з умовами цього Договору, Ліцензіар надає Ліцензіату
                  невиключну ліцензію на використання програмного забезпечення
                  «Sorcie» (далі — «Ліцензія»), що передбачає доступ до
                  функціоналу Програми через інтерфейс на веб-сайті sorcie.tech
                  або за допомогою інших засобів доступу, наданих Ліцензіаром.
                  Ліцензіат зобов'язується використовувати Програму відповідно
                  до умов цього Договору та оплачувати вартість ліцензії у
                  порядку, визначеному в Договорі.{" "}
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Повною і безумовною прийняттям (безумовним акцептом) умов
                  цього Договору вважається реєстрація Ліцензіатом на сервері
                  Ліцензіара. Якщо Ліцензіат не згоден з умовами цього Договору,
                  він не повинен використовувати Програму.{" "}
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Територія наданих прав - всі країни світу.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Актуальна редакція Договору завжди знаходиться на сайті
                  Ліцензіара за адресою{" "}
                  <a href="https://sorcie.tech/agreement">
                    sorcie.tech/agreement/
                  </a>
                  .
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Права і обов'язки сторін
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар має право:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      отримувати від Ліцензіата інформацію про дотримання умов
                      цього Договору та (або) інформацію, необхідну для
                      виконання цього Договору та додаткових угод до нього;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      отримувати винагороду відповідно до умов цього Договору;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      вносити зміни до Програми, в тому числі публікувати нові
                      версії Програми, без попереднього повідомлення Ліцензіата;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      в односторонньому порядку розірвати Договір і (або)
                      блокувати доступ Ліцензіата до Програми у випадках
                      порушення Ліцензіатом законодавства України та (або) умов
                      цього Договору;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      проводити профілактичні роботи на Сервері, для чого
                      тимчасово припиняти доступ до Програми;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      видалити обліковий запис і всі дані Ліцензіата після
                      закінчення періоду, зазначеного в п.2.2.3. цього Договору,
                      або при отриманні запиту від Ліцензіатом видалення даних,
                      або в разі порушення Ліцензіатом умов цього Договору.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар зобов'язаний:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      надати щоденний цілодобовий доступ до Програми, за
                      винятком випадків, зазначених у цьому Договорі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      забезпечити конфіденційність даних Ліцензіата з
                      урахуванням чинного законодавства України;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      забезпечити збереження даних, введених Ліцензіатом до
                      Програми, протягом терміну дії цього Договору та 1
                      (одного) року з моменту припинення дії Ліцензії, за
                      винятком випадків, зазначених у цьому Договорі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      в розумні терміни усувати збої в роботі Програми, що
                      виникли з вини Ліцензіара;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      проводити профілактичні роботи по можливості в нічний час
                      і у вихідні дні;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      здійснювати резервне копіювання даних і інформації
                      Ліцензіата.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      після отримання запиту відповідно до п. 2.3.4. цього
                      Договору в термін не пізніше 10 робочих днів і в рамках
                      технічної можливості Ліцензіара надати Ліцензіату
                      вивантаження його даних.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіат має право:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      використовувати Програму відповідно до умов Ліцензії та
                      цим Договором; 2.3.2. отримувати щоденний і цілодобовий
                      доступ до Сервера, за винятком випадків, зазначених у
                      цьому Договорі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      в будь-який час направити Ліцензіару запит про видалення
                      свого облікового запису і всіх введених в Програму даних.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      після закінчення Періоду відстрочки, але не пізніше ніж за
                      365 днів після цього, направити Ліцензіару запит про
                      вивантаження даних Ліцензіата.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      після закінчення Періоду відстрочки, але не пізніше ніж за
                      365 днів після цього, направити Ліцензіару запит про
                      вивантаження даних Ліцензіата.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіат зобов'язаний:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      дотримуватись обмежень щодо використання Програми,
                      зазначених в цьому Договорі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      сплачувати винагороду Ліцензіара в розмірі та в строки,
                      встановлені цим Договором;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      забезпечити конфіденційність Логіна і Пароля;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      негайно повідомляти Ліцензіара про будь-якому
                      недозволеному Ліцензіатом використанні свого Логіна і
                      (або) Паролю або будь-яке інше порушення безпеки;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      дотримуватися законодавства про захист персональних даних,
                      які обробляються за допомогою Програми (в тому числі
                      отримувати згоди на обробку персональних даних);
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      періодично знайомиться з актуальною редакцією цього
                      Договору;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      не надавати ні за яких обставин будь-якій третій особі
                      право на використання Програми (субліцензію).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Порядок і умови використання програми
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Для початку використання Програми Ліцензіат заповнює
                  реєстраційні форми на сайті Ліцензіара.
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      В процесі заповнення реєстраційних форм Ліцензіат
                      самостійно вибирає Логін і Пароль, необхідні для
                      подальшого доступу до Програми.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      При реєстрації Ліцензіат вводить такі дані: логін, пароль,
                      адресу електронної пошти та іншу інформацію.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Операції, які виконуються з використанням комбінації Логіна та
                  Пароля або електронної пошти Ліцензіата, вважаються діями,
                  виконаними самим Ліцензіатом.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Права на Програму надаються Ліцензіату в термін, що не
                  перевищують 2 (двох) робочих днів з моменту зарахування
                  грошових коштів на розрахунковий рахунок Ліцензіара на строк,
                  що дорівнює облікового періоду.
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У разі дострокової оплати ліцензійного винагороди (з
                      урахуванням положень п 4.7. Договору) за наступний
                      обліковий період, ліцензійні права на Програму надаються
                      не раніше закінчення терміну дії попередньої ліцензії.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Факт надання Ліцензіату Ліцензії підтверджується відкриттям
                  повного доступу Ліцензіатові до Програми відповідно до п.3.3
                  цього Договору. Факт використання Ліцензії Ліцензіатом
                  підтверджується входом до Програми за допомогою Логіна та
                  Пароля Ліцензіату протягом Облікового періоду.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар не перевіряє надану Ліцензіатом з метою виконання
                  цього Договору інформацію (дані), не несе відповідальності
                  перед будь-якими третіми особами за точність і достовірність
                  даних.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  У разі відсутності оплати ліцензійної винагороди до закінчення
                  терміну дії Ліцензії,Ліцензіату надається Період відстрочки.
                  Період відстрочки закінчується в день фактичного надходження
                  коштів від Ліцензіата або на 8-й календарний день дії Періоду
                  відстрочки. Якщо оплата ліцензійної винагороди так і не була
                  отримана до закінчення Періоду відстрочки, Ліцензіар припиняє
                  надання послуг Ліцензіату відповідно до п. 2.1.4. цього
                  Договору. Подальше використання Програми можливо після оплати
                  ліцензійного винагороди і з урахуванням плати за фактичну
                  кількість минулих днів Періоду відстрочки.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  З питань технічної підтримки Ліцензіат може звертатися за
                  контактами, вказаними на Сайті Ліцензіара за адресою{" "}
                  <a href="https://sorcie.tech">sorcie.tech</a>.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Вартість Ліцензії, порядок розрахунків і умови відшкодування
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Вартість Ліцензії
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Вартість наданої Ліцензії визначається згідно з Тарифами
                      Ліцензіара.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Ліцензіар має право на свій розсуд змінювати вартість
                      Ліцензії (тарифи) та умови зміни тарифних планів шляхом їх
                      публікації в новій редакції на Сайті Ліцензіара за адресою
                      sorcie.tech/pricing/. Нові Тарифи вступають в силу з дати
                      їх публікації.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У разі якщо Ліцензіат продовжує використання Програми
                      після вступу в силу нових Тарифів, вважається, що він
                      прийняв такі зміни Тарифів та дав свою згоду на
                      продовження співпраці відповідно до нових Тарифів.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У будь-якому випадку, перерахунок ліцензійної винагороди
                      за оплачені періоди не проводиться.
                    </li>
                  </ol>
                </li>

                <li className={`${css.liNested} ${css.nested}`}>
                  Порядок розрахунків
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Оплата ліцензійного винагороди проводиться відповідно до
                      обраного Тарифу, авансовим платежем у розмірі 100% від
                      суми обраного тарифу, не менше ніж за два робочі дні до
                      бажаної дати початку використання Програми.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Способи оплати вказані на сайті Ліцензіара за адресою{" "}
                      <a href="https://sorcie.tech/pricing/">
                        sorcie.tech/pricing/
                      </a>
                      і в Програмі.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Моментом виконання Ліцензіатом обов'язків по оплаті
                      вважається день зарахування грошових коштів на
                      розрахунковий рахунок Ліцензіара.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      В окремих випадках, при безготівковій формі оплати
                      ліцензійного винагороди на підставі рахунку,
                      підтвердженням оплати може служити факсимільна копія
                      платіжного доручення, спрямована на електронну пошту
                      Ліцензіара, зазначена в Розділі 12 цього Договору. Рішення
                      про надання доступу до Програми в даному випадку
                      залишається на розсуд Ліцензіара.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У разі дострокової оплати Ліцензії (до закінчення терміну
                      дії попередньої ліцензії) термін дії Ліцензії починається
                      після закінчення терміну дії попередньої ліцензії.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У разі надходження оплати протягом Періоду відстрочки або
                      після його закінчення – термін дії Ліцензії зменшується на
                      фактичну кількість минулих днів Періоду відстрочки.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Всі комісії, що стягуються кредитними організаціями /
                      іншими третіми особами при здійсненні платежу, оплачує
                      Сторона-платник.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Повторюваний платіж
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Повторюваний платіж здійснюється в разі вибору Ліцензіатом
                      способу оплати ліцензії банківською картою.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Повторюваний платіж активується Ліцензіатом автоматично
                      при першій оплаті. Надалі, Ліцензіар здійснює Повторюваний
                      платіж і продовжує термін дії ліцензії на термін,
                      аналогічний останньому оплаченому Ліцензіатом терміну дії
                      ліцензії.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Ліцензіат може керувати параметрами Повторюваного платежу
                      (вмикати, вимикати, змінювати період продовження ліцензії
                      та ін. параметри) в налаштуваннях Програми.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      В останній день терміну дії ліцензії Ліцензіар здійснює
                      Повторюваний платіж і продовжує термін дії ліцензії
                      відповідно до параметрів Повторюваного платежу.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У разі, коли Ліцензіару не вдається зробити Повторюваний
                      платіж по збереженому способу оплати, а Ліцензіат не
                      здійснив оплату самостійно до кінця терміну дії ліцензії –
                      починає діяти Період відстрочки, протягом якого Ліцензіар
                      здійснює спроби виконати Повторюваний платіж.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Умови повернення
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Ліцензіар гарантує Ліцензіату можливість повного
                      повернення грошових коштів, внесених в якості першої
                      оплати ліцензійної винагороди, протягом 14 (чотирнадцяти)
                      календарних днів з дати здійснення оплати, шляхом
                      надсилання електронного листа про дострокове розірвання
                      цього Договору та повернення сплачених в якості авансу
                      грошових коштів з зареєстрованої електронної пошти
                      Ліцензіата на електронну пошту Ліцензіара{" "}
                      <a href="mailto:support@robomate.tech">
                        support@robomate.tech
                      </a>
                      . Після закінчення 14 (чотирнадцяти) календарних днів з
                      дати здійснення оплати повернення за надану та використану
                      послугу не здійснюється.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Ліцензіар, у разі отримання офіційної заяви в обумовлений
                      в п. 4.4.1. Договору термін, бере на себе обов'язок
                      розглянути зазначену заяву і повернути кошти на
                      розрахунковий рахунок Ліцензіата, з якого були сплачені
                      кошти, протягом 30 (тридцяти) календарних днів з дати
                      отримання листа. У випадках, коли повернення на рахунок
                      Ліцензіата, з якого були сплачені кошти, неможливе,
                      Ліцензіат вказує новий рахунок у листі про повернення
                      сплачених в якості авансу грошових коштів.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      У будь-якому випадку, якщо прямо не буде обумовлено інше,
                      Повторювані платежі поверненню не підлягають. Процедура
                      підключення та відключення Повторюваних платежів описана у
                      п. 4.3 Договору.
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      Повернення грошових коштів означає дострокове розірвання
                      цього Договору.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Обмеження
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Програма може використовуватися виключно в господарській
                  діяльності Ліцензіата з урахуванням її функціонального
                  призначення.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіат не має права:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      намагатися обійти технічні обмеження в Програмі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      розкривати технологію, декомпілювати або деасемблувати
                      Програму;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      використовувати компоненти Програми для виклику додатків,
                      які не працюють на цій Програмі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      створювати копії Програми;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      публікувати Програму, дозволяючи іншим користувачам її
                      скопіювати;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      надавати Програму в прокат, в оренду або в тимчасове
                      користування;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      використовувати Програму для надання мережевих послуг на
                      комерційній основі;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      здійснювати використання Програми або її частин, а також
                      будь-яких інших онлайн сервісів компанії (API) після
                      закінчення терміну дії даного Договору, або його
                      дострокового розірвання;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      робити спроби щодо отримання доступу до даних інших
                      Ліцензіатів.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  У разі розірвання цього Договору, Ліцензіат зобов'язується
                  негайно припинити експлуатацію Програми та / або її окремих
                  частин, при цьому, дані Ліцензіата в Програмі зберігаються
                  протягом 365 календарних днів з моменту розірвання договору
                  або закінчення Періоду відстрочки.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Відповідальність сторін
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  За невиконання та / або неналежне виконання зобов'язань за цим
                  Договором Сторони несуть відповідальність відповідно до
                  законодавства України.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Програма надається за принципом «як є». Ліцензіар не несе
                  відповідальності за можливі збитки, прямо або побічно
                  пов'язаний із застосуванням, невірним застосуванням або через
                  непридатність Програми Ліцензіатом, втрату або пошкодження
                  даних.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Відповідальність Ліцензіара завжди обмежується сумою,
                  сплаченою Ліцензіатом.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар не несе відповідальності:
                  <ol className={css.olNested}>
                    <li className={`${css.liNested} ${css.nested}`}>
                      за неякісну роботу Програми (і завдані збитки) в разі,
                      коли Ліцензіатом (або третіми особами) самостійно були
                      внесені зміни до Програми або такі збої викликані
                      несумісністю Програми з обладнанням або іншим програмним
                      забезпеченням Ліцензіата;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      за будь-які дії Ліцензіата, пов'язані з використанням
                      Програми, документи створені в рамках використання
                      Програми;
                    </li>
                    <li className={`${css.liNested} ${css.nested}`}>
                      за відсутність доступу до Програми, коли це пов'язано з
                      діями Ліцензіата, доступом Ліцензіата до мережі Інтернет,
                      діями третіх осіб, проведенням профілактичних робіт.
                    </li>
                  </ol>
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Використання Ліцензіатом Програми спосіб, не передбачений цим
                  Договором, або після припинення дії Договору, або іншим чином
                  за межами прав, наданих за цим Договором, яке тягне за собою
                  відповідальність за порушення виключного права на результат
                  інтелектуальної діяльності, встановлену законодавством
                  України, а також право Ліцензіара на односторонньо позасудове
                  розірвання даного Договору і припинення Ліцензії.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар не несе відповідальності за обмеження доступу до
                  Програми з урахуванням положень цього Договору.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Вирішення спорів
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони будуть прагнути до вирішення всіх можливих суперечок і
                  розбіжностей, які можуть виникнути за цим Договором або в
                  зв'язку з ним, шляхом переговорів.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Спори, не врегульовані шляхом переговорів, передаються на
                  розгляд до відповідного суду м.Києва та підлягають вирішенню в
                  установленому законодавством України і належним чином
                  ратифікованих міжнародних договорів порядку.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони визнають обов'язковість досудового претензійного
                  порядку перед зверненням до суду.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар зобов'язаний відповісти на досудову претензію
                  протягом 30 (Тридцяти) робочих днів з моменту її отримання.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Форс-мажорні обставини
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  У разі виникнення обставин непереборної сили (форс-мажорних
                  обставин), а саме: війни, військових дій, блокади, ембарго,
                  пожеж, повеней, іншого стихійного лиха чи природних явищ, які
                  унеможливлюють виконання Стороною своїх зобов'язань за цим
                  Договором, Сторона, що зазнала дію таких обставин,
                  звільняється від відповідальності за таке невиконання та / або
                  неналежне виконання своїх зобов'язань за Договором на час дії
                  зазначених обставин.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторона, яка не має можливості виконати умови Договору
                  внаслідок дії форс мажорних обставин, зобов'язана повідомити
                  іншу Сторону про виникнення або припинення зазначених обставин
                  не пізніше 2 (двох) календарних днів з моменту їх виникнення
                  або припинення, в письмовій формі. Несвоєчасне повідомлення
                  про виникнення форс мажорних обставин позбавляє потерпілу
                  Сторону права посилатися на ці обставини в майбутньому.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Наступ дії і припинення дії форс-мажорних обставин
                  підтверджується довідкою, виданою Торгово-промисловою палатою
                  або іншим компетентним органом країни, резидентом якої є
                  Сторона, яка постраждала від дії форс-мажорних обставин.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  У разі коли дія зазначених обставин триває більш ніж один
                  Обліковий період поспіль та впливає на виконання Сторонами
                  своїх обов'язків за цим Договором, кожна зі Сторін має право
                  на дострокове розірвання Договору за умови, якщо вона
                  повідомить про це іншу Сторону не пізніше ніж за 10 (десять )
                  календарних днів до передбачуваної дати розірвання.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Конфіденційність
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони зобов'язуються зберігати конфіденційну інформацію, що
                  надається однією Стороною іншій Стороні.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Для умов цього Договору Сторони погодили такі визначення:
                  «Конфіденційна інформація» означає відомості, будь-яку
                  науково-технічну, технологічну, виробничу, юридичну,
                  фінансово-економічну чи іншу інформацію, в тому числі
                  комерційну таємницю і ноу-хау, що має дійсну або потенційну
                  комерційну цінність в силу її невідомості третім особам, до
                  неї немає вільного доступу на законній підставі, і Сторона
                  вживає заходів до охорони її конфіденційності.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони зобов'язуються не здійснювати розкриття Конфіденційної
                  інформації третім особам, за винятком випадків, коли
                  Конфіденційна інформація може бути розкрита з попередньої
                  письмової згоди Сторони-власника інформації або в інших
                  випадках, передбачених законодавством України. У будь-якому
                  випадку, Сторони повинні повідомляти один одного про
                  необхідність розкриття конфіденційної інформації за запитом
                  державних органів.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Жодна із Сторін цього Договору не буде намагатися прямо або
                  побічно, контактувати або співпрацювати з партнером,
                  консультантом, контрагентом, клієнтом іншої Сторони з питань,
                  пов'язаних з взаємовідносинами Сторін; за винятком дій через
                  цю іншу Сторону або отримання письмової згоди на такі дії цієї
                  іншої Сторони, а також не буде намагатися обійти іншу Сторону
                  будь-яким способом, призначеним для того, щоб обійти
                  винагороду, дохід і / або прибуток, які повинні бути отримані
                  кожної із Сторін в результаті взаємин Сторін.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони гарантують, що персональні дані їх засновників,
                  співробітників, які представляють інтереси Сторін, або інших
                  представників будуть використовуватися Сторонами з метою
                  прийняття рішень і укладення між Сторонами будь-яких
                  договорів, контрактів, угод та інших документів, спрямованих
                  на вчинення дій, що породжують юридичні наслідки для сторін.
                  Сторони гарантують забезпечення конфіденційності і безпеки
                  персональних даних відповідно до законодавства України.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Порядок укладення, термін дії, порядок розірвання договору
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Цей Договір укладається (акцептується) шляхом реєстрації
                  Ліцензіата на сервері Ліцензіара і оплати Ліцензіатом обраного
                  Тарифу і діє протягом терміну, обраного Ліцензіатом. Датою
                  укладення Договору є дата зарахування грошових коштів
                  (ліцензійної винагороди) на розрахунковий рахунок Ліцензіара в
                  розмірі, визначеному згідно з Тарифами.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Оферта (пропозиція на укладення цього Договору) діє необмежено
                  в часі з урахуванням умов, викладених у цій оферті, -
                  Ліцензійна угоді, поки Ліцензіар не припинить її дію або не
                  замінить цю пропозицію новою редакцією. Зміна умов оферти не
                  тягне зміни умов цього Договору, якщо Ліцензіат його уклав до
                  введення в дію таких змін або її припинення.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Цей Договір поширюється на оновлення і нові версії Програми.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  За винятком випадків, прямо обумовлених в цьому Договорі,
                  Договір розривається за взаємною згодою Сторін.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Видалення облікового запису Ліцензіата автоматично припиняє
                  дію цього Договору.
                </li>
              </ol>
            </li>
            <li className={css.liNested}>
              Заключні положення
              <ol className={css.olNested}>
                <li className={`${css.liNested} ${css.nested}`}>
                  Цей Договір є змішаним і містить елементи договорів
                  приєднання, публічного договору.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіат підтверджує, що він, до укладення цього Договору,
                  ознайомився з його умовами, що вони йому зрозумілі, і свідомо
                  без примусу уклав цей Договір. 11.3. Ліцензіат не може
                  пропонувати свої умови, а може тільки приєднатися до
                  запропонованої оферті.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Всі додатки та додаткові угоди до цього Договору є його
                  невід'ємною частиною.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Сторони домовилися, що всі документи з факсимільним
                  відтворенням підпису за допомогою засобів механічного або
                  іншого копіювання, електронно-цифрового підпису або іншого
                  аналога власноручного підпису мають силу таку ж, як і
                  документи з власноручним підписом.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Ліцензіар залишає за собою право вносити зміни в цей Договір в
                  односторонньому порядку. У разі якщо Ліцензіат не згоден із
                  внесеними змінами, він зобов'язаний протягом 5 робочих днів з
                  моменту зміни Договору припинити використання Програми та
                  розірвати цей Договір в односторонньому порядку.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Реквізити та контактні дані Ліцензіара вказуються в Розділі 12
                  цього Договору.
                </li>
                <li className={`${css.liNested} ${css.nested}`}>
                  Контактні дані Ліцензіата вказуються при заповненні
                  реєстраційних форм, в Особистому кабінеті в Програмі або іншим
                  узгодженим Сторонами способом. Ліцензіат несе ризик
                  повідомлення невірних (недійсних) контактних даних.
                </li>
              </ol>
            </li>
            <li className={css.liNested}> Реквізити ліцензіара</li>
          </ol>
          <p>
            {" "}
            <b>ФОП Бойко Олександр Русланович</b>{" "}
          </p>
          <p>
            <b> Код ЄДРПОУ 38933091</b>{" "}
          </p>
          <p>
            <b>Aдреса:</b> 07354, Україна, 07354, Київська обл., Вишгородський
            р-н, село Нові Петрівці, пров.Корольова, будинок 9{" "}
          </p>
          <p>
            <b>Банківські реквізити:</b> UA553220010000026006310032820 в АТ
            УНІВЕРСАЛ БАНК{" "}
          </p>
          <p>
            <b>Електронна пошта:</b>{" "}
            <a href="mailto:support@robomate.tech">support@robomate.tech</a>
          </p>
        </div>
      </div>
    </section>
  );
};
