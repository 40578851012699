import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import css from "./ResetPassword.module.css";
import {
  checkResetToken,
  resetPassword,
} from "../../redux/auth/authOperations";
import { selectTokenIsValid } from "../../redux/selectors";

const schema = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password shouldn't be longer than 20 letters"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("password"), null],
        "Passwords must match - please, check!"
      ),
  })
  .required();

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const tokenIsValid = useSelector(selectTokenIsValid);
  const navigate = useNavigate();

  const getToken = (loc) => {
    const path = loc.pathname;
    return path.substring(path.lastIndexOf("/") + 1);
  };
  const token = getToken(location);

  useEffect(() => {
    dispatch(checkResetToken(token));
  }, [dispatch, token]);

  const submitHandler = (data) => {
    dispatch(
      resetPassword({
        password: data.password,
        confirm_password: data.confirmPassword,
        reset_token: token,
      })
    );
    navigate("/");
  };

  return (
    <section>
      {!tokenIsValid && <Navigate to="/login" replace />}
      {tokenIsValid && (
        <div className="container">
          <h1 className={css.title}>Reset Your Password</h1>
          <form
            className={css.resetForm}
            onSubmit={handleSubmit(submitHandler)}
            autoComplete="off"
          >
            <div className={css.itemsContainer}>
              <label className={css.label}>
                Password
                <input
                  {...register("password")}
                  type="password"
                  name="password"
                  className={`${css.input} ${errors.password && css.error}`}
                />
                <p className={css.errorMessage}>{errors.password?.message}</p>
              </label>
            </div>
            <p className={css.paragraph}>
              Use at least one number, one uppercase letter, and one special
              character.
            </p>
            <div className={css.itemsContainer}>
              <label className={css.label}>
                Confirm Password
                <input
                  {...register("confirmPassword")}
                  type="password"
                  name="confirmPassword"
                  className={`${css.input} ${
                    errors.confirmPassword && css.error
                  }`}
                />
                <p className={css.errorMessage}>
                  {errors.confirmPassword?.message}
                </p>
              </label>
            </div>
            <button type="submit" className={css.submit}>
              Save Password
            </button>
            <p className={`${css.paragraph} ${css.centered}`}>
              Need help? Contact Sorcie Support
            </p>
          </form>
          <nav>
            <Link to="/login" className={css.returnLogin}>
              Return to login
            </Link>
          </nav>
        </div>
      )}
    </section>
  );
};
