import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { requestOtpCode, verifyTelegramApp } from "./telegramOperations";

const initialState = {
  telegramAppCreated: false,
  telegramAppVerified: false,
};

const options = [requestOtpCode, verifyTelegramApp];

const getOption = (status) => options.map((option) => option[status]);

const handleRejected = (payload) => {
  console.log("Some error happened: ", payload);
};

const telegramSlice = createSlice({
  name: "telegram",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(requestOtpCode.fulfilled, (state, { payload }) => {
        state.telegramAppCreated = true;
      })
      .addCase(verifyTelegramApp.fulfilled, (state, { payload }) => {
        state.telegramAppVerified = true;
      })
      .addMatcher(isAnyOf(...getOption("rejected")), (_state, { payload }) =>
        handleRejected(payload)
      );
  },
});

export const telegramSliceReducer = telegramSlice.reducer;
// export const { updateFilters, updateSort, resetFiltersAndSort } =
//   cvsSlice.actions;
