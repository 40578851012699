import { useEffect, useState } from "react";
import css from "./CvsTable.module.css";
import { useDispatch } from "react-redux";
import { saveComment } from "../../redux/cvs/cvsOperations";

export const CvsTable = ({ searchRunCvs }) => {
  const dispatch = useDispatch();

  const [comments, setComments] = useState({});

  useEffect(() => {
    const newComments = searchRunCvs.reduce((acc, cv) => {
      acc[cv.place] = cv.comment || "";
      return acc;
    }, {});
    setComments(newComments);
  }, [searchRunCvs]);

  const commentChange = (e, place) => {
    e.target.setAttribute("data-changed", "true");
    setComments({
      ...comments,
      [place]: e.target.value,
    });
  };

  const commentSave = (cv_id, place) => {
    const comment = comments[place];
    dispatch(saveComment({ cv_id, comment }));
  };

  const [expandedField, setExpandedField] = useState(null);

  const handleFocus = (place) => {
    setExpandedField(place);
  };

  const handleBlur = (cvId, place, e) => {
    setExpandedField(null);
    const isChanged = e.target.getAttribute("data-changed") === "true";
    if (isChanged) {
      commentSave(cvId, place);
      e.target.setAttribute("data-changed", "false");
    }
  };
  return (
    <div>
      {searchRunCvs.length > 0 ? (
        <table className={css.table}>
          <thead>
            <tr>
              <th>Place</th>
              <th>Name</th>
              <th>Relevance</th>
              <th>Rating</th>
              <th>Position</th>
              <th>Salary</th>
              <th>Age</th>
              <th>City</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {searchRunCvs.map((cv) => (
              <tr key={cv.place} className={css.row}>
                <td>{cv.place}</td>
                <td>
                  <a href={cv.url} target="_blank" rel="noopener noreferrer">
                    {cv.name}
                  </a>
                </td>
                <td>{cv.relevance.toFixed(2)}</td>
                <td>{cv.rating.toFixed(2)}</td>
                <td>{cv.position}</td>
                <td>{cv.salary}</td>
                <td>{cv.age}</td>
                <td>{cv.city}</td>
                <td className={css.commentCell}>
                  <div className={css.commentContainer}>
                    <textarea
                      className={`${css.textarea} ${
                        expandedField === cv.place
                          ? css.textareaExpanded
                          : css.textareaCollapsed
                      }`}
                      value={comments[cv.place] || ""}
                      onChange={(e) => commentChange(e, cv.place)}
                      onFocus={() => handleFocus(cv.place)}
                      onBlur={(e) => handleBlur(cv.id, cv.place, e)}
                      placeholder="Your comment"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          <p>
            Sorry, but it seems that this search has no found candidates yet.
          </p>
          <p>Please, check you e-mail to find the reason why</p>
        </div>
      )}
    </div>
  );
};

// CvsTable.propTypes = {
//   searchRunCvs: PropTypes.arrayOf(
//     PropTypes.shape({
//       place: PropTypes.string.isRequired,
//       url: PropTypes.string.isRequired,
//       relevance: PropTypes.number.isRequired,
//       rating: PropTypes.number.isRequired,
//       name: PropTypes.string.isRequired,
//       position: PropTypes.string.isRequired,
//       salary: PropTypes.number.isRequired,
//       age: PropTypes.number.isRequired,
//       city: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };
