import css from "./Footer.module.css";

export const Footer = () => {
  return (
    <section className={css.footerSection}>
      <div className={`${"container"} ${css.footerContainer}`}>
        <div className={css.growing}></div>
        <div className={css.footerItems}>
          <a href="/agreement">Agreement</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </section>
  );
};
