import { useDispatch, useSelector } from "react-redux";
import { SearchesList } from "../SearchesList/SearchesList";
import { useEffect, useState } from "react";
import { fetchSearches } from "../../redux/cvs/cvsOperations";
import { selectAccessToken, selectSearches } from "../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { resetFiltersAndSort } from "../../redux/cvs/cvsSlice";
import css from "./Searches.module.css";
import svgSrc from "../../images/sprite.svg";
import { setAuthToken } from "../../redux/auth/authApi";

export const Searches = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searches = useSelector(selectSearches);
  const accessToken = useSelector(selectAccessToken);
  const [activeButton, setActiveButton] = useState("Active");
  const [filteredSearches, setFilteredSearches] = useState(searches);

  const buttons = ["Active", "Ready", "Running", "Archive"];

  useEffect(() => {
    dispatch(resetFiltersAndSort());
    setAuthToken(accessToken);
    dispatch(fetchSearches());
  }, [dispatch, accessToken]);

  useEffect(() => {
    let selectedSearches;
    if (activeButton === "Active")
      selectedSearches = searches.filter((search) =>
        ["ready", "running"].includes(search.status)
      );
    else
      selectedSearches = searches.filter(
        (search) => search.status === activeButton.toLowerCase()
      );
    setFilteredSearches(selectedSearches);
  }, [activeButton, searches]);

  const createSearch = () => {
    dispatch(resetFiltersAndSort());
    navigate("/create/filters");
  };

  return (
    <section>
      <div className="container">
        <div className={css.searchesContainer}>
          <h1 className={css.title}>Vacancies</h1>
          <div className={css.buttonsContainer}>
            <ul className={css.buttonsList}>
              {buttons.map((buttonName) => (
                <li key={buttonName}>
                  <button
                    type="button"
                    className={`${css.filterButton} ${
                      activeButton === buttonName ? css.active : ""
                    }`}
                    onClick={() => setActiveButton(buttonName)}
                  >
                    {buttonName}
                  </button>
                </li>
              ))}
            </ul>
            <button
              onClick={createSearch}
              type="button"
              className={css.addButtton}
            >
              <svg className={css.iconSettings}>
                <use href={`${svgSrc}#plus`}></use>
              </svg>
              <span>Add new vacancy</span>
            </button>
          </div>

          {searches.length > 0 && <SearchesList searches={filteredSearches} />}
          {!searches.length && (
            <div style={{ padding: "16px" }}>NO SEARCHES YET</div>
          )}
        </div>
      </div>
    </section>
  );
};
