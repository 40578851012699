export const selectAccessToken = (state) => state.auth.accessToken;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectIsAuth = (state) => state.auth.isAuth;
export const selectUser = (state) => state.auth.user;
export const selectSearches = (state) => state.cvs.searches;
export const selectTokenIsValid = (state) => state.auth.tokenIsValid;
export const selectIsPending = (state) => state.auth.pending;
export const selectSearchFilters = (state) => state.cvs.searchFilters;
export const selectSortWages = (state) => state.cvs.sortWages;
export const selectLoadedFilters = (state) => state.cvs.loadedFilters;
export const selectCurrentSearchId = (state) => state.cvs.currentSearchId;
export const selectGlobal = (state) => state.global;
export const selectCvsTimestamps = (state) => state.cvs.cvsTimestamps;
export const selectSearchRunCvs = (state) => state.cvs.searchRunCvs;
export const selectTelegramAppCreated = (state) =>
  state.telegram.telegramAppCreated;
export const selectTelegramAppVerified = (state) =>
  state.telegram.telegramAppVerified;
