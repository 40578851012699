import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
  pending: false,
  message: "",
  filtersError: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setErrorMessage: (state, { payload }) => {
      state.message = payload;
      state.error = true;
      state.pending = false;
    },
    resetError: (state) => {
      state.error = false;
      state.message = "";
      state.pending = false;
    },
    setSuccessMessage: (state, { payload }) => {
      // console.log(payload);
      state.message = payload;
      state.error = false;
      state.pending = false;
    },
    setPending: (state) => {
      state.error = false;
      state.pending = true;
      // state.message = "";
    },
    setFiltersError: (state, { payload }) => {
      state.filtersError = payload;
    },
  },
});

export const {
  setErrorMessage,
  resetError,
  setSuccessMessage,
  setPending,
  setFiltersError,
} = globalSlice.actions;
export const globalSliceReducer = globalSlice.reducer;
