import css from "./Privacy.module.css";

export const Privacy = () => {
  return (
    <section>
      <div className="container">
        <div className={css.privacyContainer}>
          <h1 className={css.title}> ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h1>
          <p>
            ФОП Бойко Олександр Русланович поважає права користувачів і ставить
            пріоритетом збереження конфіденційності ваших персональних даних.
            Реєструючись у Програмі, ви даєте згоду на їхню обробку з метою
            надання інформаційних послуг.
          </p>
          <ul className={css.mainList}>
            <li>
              <h2 className={css.listHeaders}>
                Які персональні дані зберігаються
              </h2>
              <ul className={css.bulletsList}>
                <li>
                  Особиста інформація: ваш ПІБ, назва компанії, адреса і
                  реквізити компанії, контактні телефони, адреси електронної
                  пошти
                </li>
                <li>
                  Файли Cookies, щоб адаптувати програму під браузер, яким ви
                  користуєтесь
                </li>
                <li>IP-адреса користувача</li>
                <li>Інформація про помилки в роботі програми</li>
                <li>Поведінка користувача на сайті Sorcie</li>
              </ul>
              <p>Інформація не змінюється в процесі збору.</p>
            </li>
            <li>
              <h2 className={css.listHeaders}>
                Використання персональних даних
              </h2>
              <p>
                Ім'я, прізвище, адреса електронної пошти та інші ідентифікаційні
                дані користувача збираються з метою реєстрації та ідентифікації
                в системі Sorcie.
              </p>
              <p>
                Контактні дані користувача можуть використовуватися для
                надсилання інформаційних та маркетингових повідомлень,
                пов'язаних з новими функціями, спеціальними пропозиціями та
                оновленнями сервісу Sorcie. Існує можливість відмовитися від
                отримання таких повідомлень за допомогою функції відписки, яка
                надається в кожному електронному листі.
              </p>
            </li>
            <li>
              <h2 className={css.listHeaders}>
                {" "}
                Як змінити / видалити персональні дані
              </h2>
              <p>
                Користувачі можуть в будь-який момент змінити або видалити
                інформацію про себе в Особистому кабінеті. Після зміни або
                видалення персональних даних робота функцій Sorcie, для яких ця
                інформація є необхідною, може бути припинена.
              </p>
            </li>
            <li>
              <h2 className={css.listHeaders}>Безпека даних</h2>
              <p>
                Програма Sorcie розміщена в хмарній інфраструктурі Microsoft
                Azure, що забезпечує високий рівень безпеки та надійності.
                Використання цих хмарних сервісів гарантує, що дані користувачів
                зберігаються в датацентрах, які відповідають світовим стандартам
                безпеки.
              </p>
              <p>
                Для забезпечення додаткової безпеки та високої доступності,
                Sorcie регулярно проводить резервне копіювання даних, гарантуючи
                їх збереження та швидке відновлення у разі виникнення
                непередбачених ситуацій.
              </p>
            </li>
            <li>
              <h2 className={css.listHeaders}>Захист інформації</h2>
              <p>
                Ми докладаємо всіх зусиль для захисту конфіденційності ваших
                даних. Вся інформація, яку ми збираємо, зберігається на одному
                або декількох захищених серверах баз даних і не може вийти за
                межі нашої корпоративної мережі. Тільки ті співробітники
                компанії, яким потрібен доступ до особистої інформації
                користувачів відповідно до виконуваних обов'язків, мають доступ
                до ваших персональних даних. Всі співробітники, які мають доступ
                до інформації користувачів, підписали угоду про нерозголошення
                цієї інформації третім сторонам.
              </p>
            </li>
            <li>
              <h2 className={css.listHeaders}>Наші контакти</h2>
              <p>
                У разі виникнення питань, побажань або скарг, звертайтеся до нас
                за E-mail:{" "}
                <a href="mailto:support@robomate.tech">support@robomate.tech</a>
              </p>
            </li>
            <li>
              <h2 className={css.listHeaders}>
                Зміни в політиці конфіденційності
              </h2>
              <p>
                Політика конфіденційності сервісу Sorcie підлягає оновленню з
                часом. Користувачів заохочується регулярно перевіряти цю
                політику, щоб бути обізнаними про будь-які зміни, які можуть
                вплинути на спосіб захисту їхньої інформації.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
