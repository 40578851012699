import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectIsAuth, selectUser } from "../../redux/selectors";
import { useState } from "react";
import css from "./Header.module.css";
import svgSrc from "../../images/sprite.svg";
import { SettingsMenu } from "../SettingsMenu/SettingsMenu";

export const Header = () => {
  const isAuth = useSelector(selectIsAuth);
  const user = useSelector(selectUser);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const clickHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <section className={css.headerSection}>
      <div className={`container ${css.headerContainer}`}>
        <header className={css.headerInnerContainer}>
          <Link to="/" id="logo" className={css.logoContainer}>
            <svg className={css.iconLogo}>
              <use href={`${svgSrc}#logo`}></use>
            </svg>

            <span className={css.logo}>Sorcie</span>
          </Link>

          <div className={css.userContainer}>
            {user && (
              <button
                type="button"
                onClick={clickHandler}
                hidden={!isAuth}
                className={css.button}
              >
                <svg className={css.iconSettings}>
                  <use href={`${svgSrc}#settings`}></use>
                </svg>
              </button>
            )}
            {/* {user && <img src={imgSrc} alt="avatar" className={css.avatar} />} */}
          </div>
          {isMenuOpen && (
            <div className={css.backDrop} onClick={closeMenu}></div>
          )}

          {isMenuOpen && <SettingsMenu onCloseMenu={closeMenu} />}
        </header>
      </div>
    </section>
  );
};
