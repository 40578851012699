import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateProfile } from "../../redux/auth/authOperations";

const useQueryParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user = params.get("user");
  const months = params.get("months");
  return { user, months };
};

export const Confirm = () => {
  const { user, months } = useQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const request = {
      id: user,
      months_paid: months,
    };
    dispatch(updateProfile(request));
  }, [user, months, dispatch]);

  return (
    <section>
      <h1>Don`t reload the page. </h1>
      <p>
        <br />
        If you see error messages, you need to login to Sorcie and click the
        link in the letter again.
        <br />
        <br />
        If you see success message that Profile is updated - just close this
        tab, payment confirmed.
      </p>
    </section>
  );
};
