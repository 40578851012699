import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  checkResetToken,
  logIn,
  logOut,
  refreshAccessToken,
  registerUser,
  reportPayment,
  resetPassword,
  updateProfile,
} from "./authOperations";
import { snakeToCamel } from "../../utils";

const initialState = {
  user: null,
  accessToken: null,
  isAuth: false,
  tokenIsValid: true,
  // userIsValid: false,
};

const options = [
  registerUser,
  logIn,
  logOut,
  resetPassword,
  updateProfile,
  reportPayment,
];

const getOption = (status) => options.map((option) => option[status]);

const handleRejected = (payload) => {
  console.log("Some error happened: ", payload);
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.user = snakeToCamel(payload?.user);
        state.accessToken = payload?.access_token;
        state.isAuth = true;
      })
      .addCase(logIn.fulfilled, (state, { payload }) => {
        state.user = snakeToCamel(payload?.user);
        state.accessToken = payload?.access_token;
        state.isAuth = true;
      })
      .addCase(logOut.fulfilled, (state, { payload }) => {
        state.user = null;
        state.isAuth = false;
        state.accessToken = null;
      })
      .addCase(checkResetToken.fulfilled, (state, { payload }) => {
        state.tokenIsValid = true;
      })
      .addCase(refreshAccessToken.fulfilled, (state, { payload }) => {
        state.accessToken = payload?.access_token;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        console.log("Password reset successfully!");
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.user = snakeToCamel(payload);
      })
      .addCase(reportPayment.fulfilled, (_state, { payload }) => {
        console.log("Payment report was sent!");
        console.log(payload);
      })
      .addCase(checkResetToken.rejected, (state, { payload }) => {
        state.tokenIsValid = false;
        console.error("Reset token is not valid: ", payload);
      })
      .addCase(refreshAccessToken.rejected, (state, { payload }) => {
        state.accessToken = null;
        console.error("Token refresh failed: ", payload);
      })
      .addMatcher(isAnyOf(...getOption("rejected")), (_state, { payload }) =>
        handleRejected(payload)
      );
  },
});

const authSliceReducer = authSlice.reducer;

const persistConfig = {
  key: "token",
  version: 1,
  storage,
  whitelist: ["accessToken", "user", "isAuth"],
};
export const authSlicePersistedReducer = persistReducer(
  persistConfig,
  authSliceReducer
);
export const { updateUser } = authSlice.actions;
