import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteSearchById,
  fetchCandidates,
  toggleSearchById,
} from "../../redux/cvs/cvsOperations";
import css from "./SearchesList.module.css";
import svgSrc from "../../images/sprite.svg";
import { convertDateTime } from "../../utils";
import { selectUser } from "../../redux/selectors";

export const SearchesList = ({ searches }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(selectUser).id;
  const expirationDate = useSelector(selectUser).expirationDate;

  const openHandler = (searchId) => {
    navigate(`/search/${searchId}/filters`);
  };

  const deleteHandler = (searchId) => {
    dispatch(deleteSearchById(searchId));
  };

  const runHandler = (searchId) => {
    dispatch(fetchCandidates({ searchId, userId, expirationDate }));
  };

  const archiveHandler = (searchId, searchStatus) => {
    dispatch(toggleSearchById({ searchId, searchStatus }));
  };

  return (
    <ul>
      {searches.map((search) => (
        <li
          key={search.id}
          className={`${css.searchItem} ${
            search.status === "running" ? css.inactive : ""
          }`}
        >
          <div
            className={`${css.searchOpener} ${
              search.status === "archive" ? css.inactive : ""
            }`}
            onClick={() => openHandler(search.id)}
          >
            <p className={css.position}>{search.keyword}</p>
            <p className={css.city}>{search.city}</p>
            <p className={css.city}>
              Last run:&nbsp;
              {search.status === "running"
                ? "running..."
                : convertDateTime(search.lastRun)}
            </p>
          </div>
          <div className={css.buttonsContainer}>
            {search.status !== "archive" && (
              <button
                type="button"
                className={css.button}
                onClick={() => runHandler(search.id)}
              >
                <svg className={css.iconSettings}>
                  <use href={`${svgSrc}#run`}></use>
                </svg>
                <span>Run</span>
              </button>
            )}

            {search.status !== "archive" && (
              <button
                type="button"
                className={css.button}
                onClick={() => archiveHandler(search.id, search.status)}
              >
                <svg className={css.iconSettings}>
                  <use href={`${svgSrc}#archive`}></use>
                </svg>
                <span>Archive</span>
              </button>
            )}

            {search.status === "archive" && (
              <button
                type="button"
                className={css.button}
                onClick={() => archiveHandler(search.id, search.status)}
              >
                <svg className={css.iconSettings}>
                  <use href={`${svgSrc}#activate`}></use>
                </svg>
                <span>Activate</span>
              </button>
            )}

            <button
              className={css.button}
              type="button"
              onClick={() => deleteHandler(search.id)}
            >
              <svg className={css.iconSettings}>
                <use href={`${svgSrc}#delete`}></use>
              </svg>
              Delete
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};
