import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { authSlicePersistedReducer } from "./auth/authSlice";
import { devToolsEnhancer } from "@redux-devtools/extension";
import { cvsSliceReducer } from "./cvs/cvsSlice";
import { globalSliceReducer } from "./global/glolbalSlice";
import { telegramSliceReducer } from "./telegram/telegramSlice";
const enhancer = devToolsEnhancer();
export const store = configureStore({
  reducer: {
    auth: authSlicePersistedReducer,
    cvs: cvsSliceReducer,
    global: globalSliceReducer,
    telegram: telegramSliceReducer,
  },
  enhancer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
