import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch } from "react-redux";
import { updateFilters } from "../../redux/cvs/cvsSlice";

export const MultiSlider = ({ min, max, minValue, maxValue, id }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (id === "age")
      dispatch(updateFilters({ minAge: e.minValue, maxAge: e.maxValue }));
    else
      dispatch(updateFilters({ minSalary: e.minValue, maxSalary: e.maxValue }));
  };
  return (
    <MultiRangeSlider
      min={min}
      max={max}
      step={1}
      minValue={minValue}
      maxValue={maxValue}
      baseClassName="multiSlider"
      ruler={false}
      label={false}
      barInnerColor="#3670E8"
      thumbLeftColor="#3670E8"
      thumbRightColor="#3670E8"
      onChange={(e) => {
        handleChange(e);
      }}
    />
  );
};
