import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import css from "./ResetRequest.module.css";
import { resetRequest } from "../../redux/auth/authOperations";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
  })
  .required();

export const ResetRequest = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const submitHandler = (data) => {
    dispatch(
      resetRequest({
        email: data.email,
      })
    );
  };

  return (
    <section>
      <div className="container">
        <h1 className={css.title}>Reset Your Password</h1>
        <form
          className={css.resetForm}
          onSubmit={handleSubmit(submitHandler)}
          noValidate
        >
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Email
              <input
                {...register("email")}
                type="email"
                name="email"
                placeholder="adam.smith@gmail.com"
                className={`${css.input} ${errors.email && css.error}`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.email?.message}</p>
            </label>
          </div>

          <p className={css.paragraph}>
            We'll send you an email with instructions to reset your password.
          </p>
          <button type="submit" className={css.submit}>
            Send Reset Link
          </button>
          <p className={`${css.paragraph} ${css.centered}`}>
            Need help? Contact Sorcie Support
          </p>
        </form>

        <nav>
          <Link to="/login" className={css.returnLogin}>
            Return to login
          </Link>
        </nav>
      </div>
    </section>
  );
};
