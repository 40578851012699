export const areEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (val1.join("") !== val2.join("")) {
        return false;
      }
    } else {
      if (val1.toString() !== val2.toString()) {
        return false;
      }
    }
  }
  return true;
};

export const camelToSnake = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== "") {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`
      );
      acc[snakeKey] = obj[key];
    }
    return acc;
  }, {});
};

export const snakeToCamel = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_[a-z]/g, (match) => match[1].toUpperCase());
    acc[camelKey] = obj[key];
    return acc;
  }, {});
};

export const convertDateTime = (dateTime) => {
  if (!dateTime) {
    return "Never";
  }
  const lastRunUtc = new Date(dateTime);
  const options = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return lastRunUtc.toLocaleString("en-GB", options);
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const subscriptionIsValid = (dateTimeString) => {
  const expirationDate = new Date(dateTimeString);
  const today = new Date();
  return expirationDate > today;
};
