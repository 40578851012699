import { Outlet } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Toast } from "../components/Toast/Toast";
import { Loader } from "../components/Loader/Loader";
import { useSelector } from "react-redux";
import { selectGlobal } from "../redux/selectors";

export const Home = () => {
  const pending = useSelector(selectGlobal).pending;
  return (
    <>
      <Header />
      {pending && <Loader />}
      <Toast />
      <Outlet />
      <Footer />
    </>
  );
};
