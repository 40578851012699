import { useDispatch } from "react-redux";
import css from "./LoginForm.module.css";
import { logIn } from "../../redux/auth/authOperations";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password shouldn't be longer than 20 letters"),
  })
  .required();

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const submitHandler = (data) => {
    dispatch(
      logIn({
        username: data.email,
        password: data.password,
      })
    );
  };

  return (
    <section className={css.loginSection}>
      <div className="container">
        <h1 className={css.title}>Log in to Sorcie</h1>
        <form
          className={css.loginForm}
          onSubmit={handleSubmit(submitHandler)}
          autoComplete="off"
        >
          <label className={css.label}>
            Username
            <input
              {...register("email")}
              name="email"
              placeholder="email@example.com"
              className={`${css.input} ${errors.email && css.error}`}
              autoComplete="on"
            />
            <p className={css.errorMessage}>{errors.email?.message}</p>
          </label>
          <label className={css.label}>
            Password
            <input
              {...register("password")}
              name="password"
              type="password"
              className={`${css.input} ${errors.password && css.error}`}
            />
            <p className={css.errorMessage}>{errors.password?.message}</p>
          </label>
          <button type="submit" className={css.submit}>
            Log In
          </button>
        </form>
        <nav className={css.navLinks}>
          <Link to="/register" className={css.navLink}>
            Don't have an account?
          </Link>
          <Link to="/reset" className={css.navLink}>
            Forgot your password?
          </Link>
        </nav>
      </div>
    </section>
  );
};
