import { useDispatch, useSelector } from "react-redux";
import css from "./Profile.module.css";
import { Link, useNavigate } from "react-router-dom";
import { updateProfile } from "../../redux/auth/authOperations";
import { selectGlobal, selectUser } from "../../redux/selectors";
import { updateUser } from "../../redux/auth/authSlice";
import { camelToSnake } from "../../utils";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";

const schema = yup
  .object({
    firstName: yup
      .string()
      .required("First name is required")
      .min(2, "First name should be 2 letters at least")
      .max(60, "First name shouldn't be longer than 60 letters"),
    lastName: yup
      .string()
      .max(60, "Last name shouldn't be longer than 60 letters")
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: "Last name should be 2 letters at least",
      }),
    phoneNumber: yup
      .string()
      .matches(
        /^\+\d{2} \(\d{3}\) \d{3}-\d{2}-\d{2}$|^\+__ \(0__\) ___-__-__$|^$/,
        "Invalid phone number"
      ),
    companyName: yup
      .string()
      .max(60, "Company name shouldn't be longer than 60 letters")
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: "Company name should be 2 letters at least",
      }),
  })
  .required();

export const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const backupUser = useRef(user);
  const navigate = useNavigate();
  const { error, message } = useSelector(selectGlobal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (error) {
      dispatch(updateUser(backupUser.current));
    } else if (message) {
      navigate("/");
    }
  }, [error, message, dispatch, navigate]);

  const submitHandler = () => {
    const request = camelToSnake(user);
    request.username = request.first_name;
    delete request.email;
    dispatch(updateProfile(request));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateUser({ [name]: value }));
  };

  return (
    <section>
      <div className="container">
        <h1 className={css.title}>Update your information</h1>
        <form
          className={css.registerForm}
          onSubmit={handleSubmit(submitHandler)}
          autoComplete="off"
        >
          <div className={css.itemsContainer}>
            <label className={css.label}>
              First Name
              <input
                {...register("firstName")}
                name="firstName"
                value={user.firstName}
                type="text"
                placeholder="Enter your first name"
                className={`${css.input} ${css.short} ${
                  errors.firstName && css.error
                }`}
                onChange={handleChange}
              />
              <p className={css.errorMessage}>{errors.firstName?.message}</p>
            </label>
            <label className={css.label}>
              Last Name
              <input
                {...register("lastName")}
                type="text"
                name="lastName"
                value={user.lastName || ""}
                placeholder="Enter your last name"
                className={`${css.input} ${css.short} ${
                  errors.lastName && css.error
                }`}
                onChange={handleChange}
              />
              <p className={css.errorMessage}>{errors.lastName?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Phone Number
              <InputMask
                {...register("phoneNumber")}
                name="phoneNumber"
                mask="+99 (999) 999-99-99"
                value={user.phoneNumber || ""}
                placeholder="Enter your phone number"
                type="tel"
                className={`${css.input} ${errors.phoneNumber && css.error}`}
                onChange={handleChange}
              />
              <p className={css.errorMessage}>{errors.phoneNumber?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Company Name
              <input
                {...register("companyName")}
                type="text"
                name="companyName"
                value={user.companyName || ""}
                placeholder="Enter your company name"
                className={css.input}
                onChange={handleChange}
              />
              <p className={css.errorMessage}>{errors.companyName?.message}</p>
            </label>
          </div>

          <button type="submit" className={css.submit}>
            Save Changes
          </button>
          <p className={`${css.paragraph} ${css.centered}`}>
            By clicking 'Save Changes', you agree to our Terms of Service and
            Privacy Policy.
          </p>
        </form>
        <nav>
          <Link to="/login" className={css.returnDashboard}>
            Return to the dashboard
          </Link>
        </nav>
      </div>
    </section>
  );
};
