import { useDispatch } from "react-redux";
import css from "./Register.module.css";
import { registerUser } from "../../redux/auth/authOperations";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";

const schema = yup
  .object({
    firstName: yup
      .string()
      .required("First name is required")
      .min(2, "First name should be 2 letters at least")
      .max(60, "First name shouldn't be longer than 60 letters"),
    lastName: yup
      .string()
      .max(60, "Last name shouldn't be longer than 60 letters")
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: "Last name should be 2 letters at least",
      }),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    phone: yup
      .string()
      .matches(
        /^\+\d{2} \(0\d{2}\) \d{3}-\d{2}-\d{2}$|^\+__ \(0__\) ___-__-__$|^$/,
        "Invalid phone number"
      ),
    company: yup
      .string()
      .max(60, "Company name shouldn't be longer than 60 letters")
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: "Company name should be 2 letters at least",
      }),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password must be at least 4 characters")
      .max(20, "Password shouldn't be longer than 20 letters"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("password"), null],
        "Passwords must match - please, check!"
      ),
  })
  .required();

export const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const dispatch = useDispatch();

  const submitHandler = (data) => {
    const request = {
      username: data.firstName,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phone,
      company_name: data.company,
      password: data.password,
    };

    const filteredRequest = {};
    for (let key in request) {
      if (request[key]) {
        filteredRequest[key] = request[key];
      }
    }
    dispatch(registerUser(filteredRequest));
  };

  return (
    <section>
      <div className="container">
        <h1 className={css.title}>Register form</h1>
        <form
          className={css.registerForm}
          onSubmit={handleSubmit(submitHandler)}
          autoComplete="on"
          noValidate
        >
          <div className={css.itemsContainer}>
            <label className={css.label}>
              First Name
              <input
                {...register("firstName")}
                type="text"
                placeholder="Enter your first name"
                className={`${css.input} ${css.short} ${
                  errors.firstName && css.error
                }`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.firstName?.message}</p>
            </label>

            <label className={css.label}>
              Last Name
              <input
                type="text"
                {...register("lastName")}
                placeholder="Enter your last name"
                className={`${css.input} ${css.short} ${
                  errors.lastName && css.error
                }`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.lastName?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Email
              <input
                {...register("email")}
                type="email"
                placeholder="Enter your email address"
                className={`${css.input} ${errors.email && css.error}`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.email?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Phone Number
              <InputMask
                mask="+99 (099) 999-99-99"
                placeholder="Enter your phone number"
                {...register("phone")}
                type="tel"
                className={`${css.input} ${errors.phone && css.error}`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.phone?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Company Name
              <input
                {...register("company")}
                type="text"
                placeholder="Enter your company name"
                className={`${css.input} ${errors.company && css.error}`}
                autoComplete="on"
              />
              <p className={css.errorMessage}>{errors.company?.message}</p>
            </label>
          </div>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Password
              <input
                type="password"
                {...register("password")}
                placeholder="Enter your password"
                className={`${css.input} ${errors.password && css.error}`}
              />
              <p className={css.errorMessage}>{errors.password?.message}</p>
            </label>
          </div>

          <p className={css.paragraph}>
            Use at least one number, one uppercase letter, and one special
            character.
          </p>
          <div className={css.itemsContainer}>
            <label className={css.label}>
              Confirm Password
              <input
                type="password"
                {...register("confirmPassword")}
                placeholder="Re-enter your password"
                // required
                className={`${css.input} ${
                  errors.confirmPassword && css.error
                }`}
              />
              <p className={css.errorMessage}>
                {errors.confirmPassword?.message}
              </p>
            </label>
          </div>

          <button type="submit" className={css.submit}>
            Save Changes
          </button>
          <p className={`${css.paragraph} ${css.centered}`}>
            By clicking 'Save Changes', you agree to our Terms of Service and
            Privacy Policy.
          </p>
        </form>

        <nav>
          <Link to="/login" className={css.returnDashboard}>
            Return to the dashboard
          </Link>
        </nav>
      </div>
    </section>
  );
};
