import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSearchId,
  selectCvsTimestamps,
  selectLoadedFilters,
  selectSearchRunCvs,
  selectUser,
} from "../../redux/selectors";
import {
  fetchCVsBySearchRun,
  fetchCandidates,
  sendEmail,
} from "../../redux/cvs/cvsOperations";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import css from "./Candidates.module.css";
import { convertDateTime } from "../../utils";
import PaginatedCvsTable from "../PaginatedCvsTable/PaginatedCvsTable";

export const Candidates = () => {
  const searchId = useSelector(selectCurrentSearchId);
  const userId = useSelector(selectUser).id;
  const expirationDate = useSelector(selectUser).expirationDate;
  const location = useLocation();
  const cvsTimestamps = useSelector(selectCvsTimestamps);
  const searchRunCvs = useSelector(selectSearchRunCvs);
  const navigate = useNavigate();
  const currentFiltersPath = location.pathname;
  const dispatch = useDispatch();

  const [selectedRun, setSelectedRun] = useState("");

  useEffect(() => {
    setSelectedRun(cvsTimestamps[0]);
  }, [cvsTimestamps]);

  useEffect(() => {
    if (!searchId && currentFiltersPath.startsWith("/search")) {
      navigate(currentFiltersPath.replace("candidates", "filters"));
    }
  }, [location, navigate, searchId, currentFiltersPath]);

  const runHandler = (searchId) => {
    dispatch(fetchCandidates({ searchId, userId, expirationDate }));
  };

  const clickHandler = () => {
    dispatch(sendEmail({ searchId, expirationDate, selectedRun }));
  };

  const getCvsByDate = (timestamp) => {
    setSelectedRun(timestamp);
    dispatch(fetchCVsBySearchRun({ searchId, expirationDate, timestamp }));
  };
  return (
    <div className={css.candidatesContainer}>
      <div className={css.titleContainer}>
        <h1 className={css.title}>Candidates</h1>
        <button
          onClick={() => {
            runHandler(searchId);
          }}
          type="button"
          className={css.button}
        >
          Run Search
        </button>
      </div>
      <div className={css.buttonsContainer}>
        <select
          id="runs"
          name="runs"
          onChange={(e) => getCvsByDate(e.target.value)}
          value={selectedRun}
          className={css.button}
        >
          <option value="" disabled className={css.option}>
            -- Select Run --
          </option>
          {cvsTimestamps.map((timestamp, index) => (
            <option
              key={index}
              value={timestamp}
              className={css.option}
              // selected={index === 0}
            >
              {convertDateTime(timestamp)}
            </option>
          ))}
        </select>

        <button onClick={clickHandler} type="button" className={css.button}>
          Export Excel
        </button>
      </div>
      <PaginatedCvsTable searchRunCvs={searchRunCvs} itemsPerPage={10} />
      {/* <p className={css.text}>Download a list of all your candidates.</p>
        <p className={css.text}>Last run:&nbsp; {convertDateTime(lastRun)}</p> */}
    </div>
  );
};
