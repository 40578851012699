import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  auth,
  clearAuthToken,
  loginConfig,
  refreshConfig,
  registerConfig,
  setAuthToken,
} from "./authApi";
import { setPending, setSuccessMessage } from "../global/glolbalSlice";
import { createGreeting, dispatchError } from "../global/globalOperations";
import { deleteCookie } from "../../utils";

export const registerUser = createAsyncThunk(
  "auth/register",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      const response = await auth.post("/user", formData, registerConfig);
      setAuthToken(response.data.access_token);
      const greeting = createGreeting(
        response.data.user.first_name,
        response.data.user.last_name
      );
      thunkAPI.dispatch(setSuccessMessage(greeting));
      return response.data;
    } catch (error) {
      return dispatchError(error, thunkAPI);
    }
  }
);

export const logIn = createAsyncThunk(
  "auth/login",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      const response = await auth.post("/login", formData, loginConfig);
      setAuthToken(response.data.access_token);
      const greeting = createGreeting(
        response.data.user.first_name,
        response.data.user.last_name
      );
      thunkAPI.dispatch(setSuccessMessage(greeting));
      return response.data;
    } catch (error) {
      return dispatchError(error, thunkAPI);
    }
  }
);

export const resetRequest = createAsyncThunk(
  "auth/resetRequest",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      const response = await auth.post(
        "/forget-password",
        formData,
        registerConfig
      );
      thunkAPI.dispatch(
        setSuccessMessage(
          `Your request for a new password sent successfully to ${formData.email}`
        )
      );
      return response.data;
    } catch (error) {
      return dispatchError(error, thunkAPI);
    }
  }
);

export const checkResetToken = createAsyncThunk(
  "auth/checkResetToken",
  async (token, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      const response = await auth.post(
        "/check-token",
        { reset_token: token },
        registerConfig
      );
      thunkAPI.dispatch(setSuccessMessage("Now you can reset your password"));
      return response.data;
    } catch (error) {
      return dispatchError(error, thunkAPI);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (formData, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      const response = await auth.post(
        "/reset-password",
        formData,
        registerConfig
      );
      thunkAPI.dispatch(
        setSuccessMessage("Your password has been reset successfully")
      );
      return response.data;
    } catch (error) {
      return dispatchError(error, thunkAPI);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (formData, thunkAPI) => {
    const makeUpdateRequest = async (formData, user_id) => {
      const response = await auth.put(
        `/user/${user_id}`,
        formData,
        registerConfig
      );
      thunkAPI.dispatch(
        setSuccessMessage("Your profile has been updated successfully")
      );
      return response.data;
    };

    const user_id = formData.id;
    delete formData.id;
    try {
      thunkAPI.dispatch(setPending());
      return await makeUpdateRequest(formData, user_id);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeUpdateRequest(formData, user_id);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const reportPayment = createAsyncThunk(
  "auth/reportPayment",
  async (formData, thunkAPI) => {
    const makeReportRequest = async (formData, user_id) => {
      const response = await auth.post(
        `/user/${user_id}`,
        formData,
        registerConfig
      );
      thunkAPI.dispatch(
        setSuccessMessage("Your payment report was successfully delivered!")
      );
      return response.data;
    };

    const user_id = formData.id;
    delete formData.id;
    try {
      thunkAPI.dispatch(setPending());
      return await makeReportRequest(formData, user_id);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeReportRequest(formData, user_id);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setPending());
      clearAuthToken();
      const response = await auth.post("/refresh", refreshConfig);
      setAuthToken(response.data.access_token);
      setSuccessMessage();
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        thunkAPI.dispatch(logOut());
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const logOut = createAsyncThunk("auth/logOut", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(setPending());
    clearAuthToken();
    deleteCookie("logout_token");
    const response = await auth.post("/logout");
    thunkAPI.dispatch(setSuccessMessage("Logged out successfully"));
    return response.data;
  } catch (error) {
    const { payload } = dispatchError(error, thunkAPI);
    console.log(`Error: ${payload}, but user is logged out`);
    return {};
  }
});
