import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth, registerConfig } from "../auth/authApi";
import { setPending, setSuccessMessage } from "../global/glolbalSlice";
import { refreshAccessToken } from "../auth/authOperations";
import { dispatchError } from "../global/globalOperations";

export const requestOtpCode = createAsyncThunk(
  "telegram/requestOtpCode",
  async (apiData, thunkAPI) => {
    const makerequestOtpCode = async (apiData) => {
      const response = await auth.post("/tg", apiData, registerConfig);
      thunkAPI.dispatch(setSuccessMessage());
      return response.status;
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makerequestOtpCode(apiData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makerequestOtpCode(apiData);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);

export const verifyTelegramApp = createAsyncThunk(
  "telegram/verifyTelegramApp",
  async ({ otpCode }, thunkAPI) => {
    const makeverifyTelegramApp = async (otpCode) => {
      const response = await auth.get(`/tg/${otpCode}`, registerConfig);
      thunkAPI.dispatch(setSuccessMessage());
      return response.data;
    };

    try {
      thunkAPI.dispatch(setPending());
      return await makeverifyTelegramApp(otpCode);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          await thunkAPI.dispatch(refreshAccessToken());
          return await makeverifyTelegramApp(otpCode);
        } catch (refreshError) {
          return dispatchError(refreshError, thunkAPI);
        }
      }
      return dispatchError(error, thunkAPI);
    }
  }
);
