import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { CvsTable } from "../CvsTable/CvsTable";
import css from "./PaginatedCvsTable.module.css";

function PaginatedCvsTable({ searchRunCvs, itemsPerPage }) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = searchRunCvs.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(searchRunCvs.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % searchRunCvs.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <CvsTable searchRunCvs={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={null}
        previousLabel={null}
        // nextLabel="forward >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        pageCount={pageCount}
        // previousLabel="< back"
        renderOnZeroPageCount={null}
        containerClassName={css.paginator}
        pageClassName={css.pagesBackground}
        pageLinkClassName={css.pageNumber}
        activeClassName={css.activeBackground}
        breakClassName={css.ellipsisContainer}
      />
    </>
  );
}

export default PaginatedCvsTable;
