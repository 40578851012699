import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobal } from "../../redux/selectors";
import { resetError } from "../../redux/global/glolbalSlice";
export const Toast = () => {
  const { error, message } = useSelector(selectGlobal);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("MESSAGE: ", message);
    // console.log("ERROR: ", error);
    if (error) {
      toast.error(message);
    } else {
      if (message) {
        toast.success(message);
      }
    }
    if (error || message) dispatch(resetError());
  }, [error, message, dispatch]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};
