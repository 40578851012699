import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import css from "./SettingsMenu.module.css";
import { logOut } from "../../redux/auth/authOperations";

export const SettingsMenu = ({ onCloseMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch(logOut());
    onCloseMenu();
  };
  const moveToPage = (page) => {
    navigate(page);
    onCloseMenu();
  };
  return (
    <>
      <nav className={css.menuContainer}>
        <ul>
          <li onClick={() => moveToPage("profile")} className={css.menuItem}>
            <Link>Profile</Link>
          </li>
          <li onClick={() => moveToPage("telegram")} className={css.menuItem}>
            <Link>Telegram connect</Link>
          </li>
          <li onClick={() => moveToPage("pricing")} className={css.menuItem}>
            <Link>Payment</Link>
          </li>
          <li onClick={() => moveToPage("agreement")} className={css.menuItem}>
            <Link>Agreement</Link>
          </li>
          <li
            onClick={() => moveToPage("privacy-policy")}
            className={css.menuItem}
          >
            <Link>Privacy Policy</Link>
          </li>
          <li onClick={handleLogOut} className={css.menuItem}>
            <Link>Logout</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};
