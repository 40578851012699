import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/selectors";
import { useState } from "react";
import css from "./Payment.module.css";
import { reportPayment } from "../../redux/auth/authOperations";

export const Payment = () => {
  const MONTHLY_FEE = 160;
  const user = useSelector(selectUser);
  const expDate = user.expirationDate;
  const date = new Date(expDate);
  const today = new Date();

  const formattedDate = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.${
    date.getMonth() + 1 < 10 ? "0" : ""
  }${date.getMonth() + 1}.${date.getFullYear()} at ${date.getHours()}:${
    date.getMinutes() < 10 ? "0" : ""
  }${date.getMinutes()}`;

  const formattedToday = `${
    today.getDate() < 10 ? "0" : ""
  }${today.getDate()}.${today.getMonth() + 1 < 10 ? "0" : ""}${
    today.getMonth() + 1
  }.${today.getFullYear()}`;

  const [months, setMonths] = useState(1);
  const [readyToPay, setReadyToPay] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setMonths(parseInt(value));
    setReadyToPay(false);
  };

  const openRequisites = () => {
    setReadyToPay(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const request = {
      id: user.id,
      months_paid: months,
    };
    dispatch(reportPayment(request));
  };

  return (
    <section className={css.paySection}>
      <div className="container">
        <h1 className={css.title}>
          Your Sorcie subscription expires on {formattedDate}
        </h1>
        <h2 className={css.price}>The service fee is just </h2>
        <p className={css.fee}>${MONTHLY_FEE * months}</p>
        <h2 className={css.price}>
          for {months} month
          {months !== 1 ? "s" : ""}
        </h2>

        <p className={css.monthsCount}>
          Pay for {months} month{months !== 1 ? "s" : ""} upfront
        </p>
        <form onSubmit={submitHandler} className={css.rangeContainer}>
          <label htmlFor="monthSelector" className={css.months}>
            <span className={css.monthsLimits}>1</span>
            <input
              type="range"
              name="monthSelector"
              min="1"
              max="12"
              step="1"
              value={months}
              onChange={handleChange}
              className={css.sortRange}
            />
            <span className={css.monthsLimits}>12</span>
          </label>
          <button type="button" onClick={openRequisites} className={css.button}>
            Pay
          </button>

          {readyToPay && (
            <div className={css.requisitesContainer}>
              <p className={css.requisiteText}>
                ФОП Бойко Олександр Русланович <br />
                Код ЄДРПОУ 38933091 <br />
                IBAN: UA553220010000026006310032820 в АТ УНІВЕРСАЛ БАНК <br />
                Електронна пошта: support@robomate.tech
                <br />
                <br />
                Призначення платежу: згідно ліцензійного договору <br />
                (публічної оферти) на сайті{" "}
                <a href="https://sorcie.tech">sorcie.tech</a> <br />
                Сумма до сплати: ${MONTHLY_FEE * months} за курсом НБУ на{" "}
                {formattedToday}
              </p>
              <button type="submit" className={css.button}>
                Report payment
              </button>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};
