import { useDispatch, useSelector } from "react-redux";
import css from "./Telegram.module.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import {
  requestOtpCode,
  verifyTelegramApp,
} from "../../redux/telegram/telegramOperations";
import {
  selectTelegramAppCreated,
  selectTelegramAppVerified,
} from "../../redux/selectors";

// Schema for creating a Telegram app
const schemaCreateApp = yup.object().shape({
  apiId: yup
    .number()
    .required("api_id is required")
    .typeError("apiId should be a number")
    .nullable(),
  apiHash: yup.string().required("api_hash is required"),
  phone: yup
    .string()
    .required("Telegram number is required")
    .matches(/^\+\d{2} \(0\d{2}\) \d{3}-\d{2}-\d{2}$/, "Invalid phone number"),
});

// Schema for verifying a Telegram app
const schemaVerifyApp = yup.object().shape({
  otpCode: yup.string().required("OTP code is required"),
});

export const Telegram = () => {
  const dispatch = useDispatch();
  const telegramAppCreated = useSelector(selectTelegramAppCreated);
  const telegramAppVerified = useSelector(selectTelegramAppVerified);

  // Choose schema based on the state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      telegramAppCreated ? schemaVerifyApp : schemaCreateApp
    ),
    mode: "onBlur",
  });

  // Handler for form submission when creating the app
  const submitHandlerCreate = (data) => {
    const request = {
      api_id: data.apiId,
      api_hash: data.apiHash,
      phone_number: data.phone,
    };
    dispatch(requestOtpCode(request));
  };

  // Handler for form submission when verifying the app
  const submitHandlerVerify = (data) => {
    const request = {
      otpCode: data.otpCode,
    };
    dispatch(verifyTelegramApp(request));
  };

  return (
    <section>
      <div className="container">
        <h1 className={css.title}>Telegram Under Construction</h1>

        {/* {!telegramAppCreated && (
          <form
            className={css.registerForm}
            onSubmit={handleSubmit(submitHandlerCreate)}
            autoComplete="on"
            noValidate
          >
            <div className={css.itemsContainer}>
              <label className={css.label}>
                App api_id:
                <input
                  {...register("apiId")}
                  type="text"
                  placeholder="Enter your api_id"
                  className={`${css.input} ${css.short} ${
                    errors.apiId ? css.error : ""
                  }`}
                />
                <p className={css.errorMessage}>{errors.apiId?.message}</p>
              </label>

              <label className={css.label}>
                App api_hash:
                <input
                  {...register("apiHash")}
                  type="text"
                  placeholder="Enter your api_hash"
                  className={`${css.input} ${css.short} ${
                    errors.apiHash ? css.error : ""
                  }`}
                />
                <p className={css.errorMessage}>{errors.apiHash?.message}</p>
              </label>

              <label className={css.label}>
                Phone Number:
                <InputMask
                  mask="+99 (099) 999-99-99"
                  placeholder="Enter your phone number"
                  {...register("phone")}
                  type="tel"
                  className={`${css.input} ${errors.phone ? css.error : ""}`}
                />
                <p className={css.errorMessage}>{errors.phone?.message}</p>
              </label>
            </div>
            <button type="submit" className={css.submit}>
              Request OTP
            </button>
          </form>
        )}

        {telegramAppCreated && !telegramAppVerified && (
          <form
            className={css.registerForm}
            onSubmit={handleSubmit(submitHandlerVerify)}
            autoComplete="on"
            noValidate
          >
            <div className={css.itemsContainer}>
              <label className={css.label}>
                OTP code:
                <input
                  {...register("otpCode")}
                  type="text"
                  placeholder="Enter OTP code"
                  className={`${css.input} ${errors.otpCode ? css.error : ""}`}
                />
                <p className={css.errorMessage}>{errors.otpCode?.message}</p>
              </label>
            </div>
            <button type="submit" className={css.submit}>
              Verify OTP
            </button>
          </form>
        )} */}
      </div>
    </section>
  );
};
