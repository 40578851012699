import { useSelector } from "react-redux";
import { NavLink, Navigate, Outlet, Route, Routes } from "react-router-dom";
import {
  selectCurrentSearchId,
  selectGlobal,
  selectIsAuth,
  selectLoadedFilters,
  selectSearchFilters,
} from "../../redux/selectors";
import { Filters } from "../Filters/Filters";
import { Sort } from "../Sort/Sort";
import { Candidates } from "../Candidates/Candidates";
import { PrivateRoute } from "../../App";
import css from "./SearchDetails.module.css";
import { areEqual } from "../../utils";

export const SearchDetails = () => {
  const isAuth = useSelector(selectIsAuth);
  const currentSearchId = useSelector(selectCurrentSearchId);
  const currentFilters = useSelector(selectSearchFilters);
  const loadedFilters = useSelector(selectLoadedFilters);
  const filtersError = useSelector(selectGlobal).filtersError;
  const isUpdate = areEqual(currentFilters, loadedFilters);

  return (
    <section>
      <div className={`container  ${css.detailsContainer}`}>
        <nav className={css.navigation}>
          <NavLink to="filters" className={css.navItem}>
            Searching Criteria
          </NavLink>
          {!filtersError && (
            <NavLink to="sort" className={css.navItem}>
              Sorting
            </NavLink>
          )}

          {currentSearchId && isUpdate && (
            <NavLink to="candidates" className={css.navItem}>
              Candidates
            </NavLink>
          )}
        </nav>

        <Routes>
          <Route
            path="filters"
            element={<PrivateRoute element={<Filters />} isAuth={isAuth} />}
          />
          <Route
            path="sort"
            element={<PrivateRoute element={<Sort />} isAuth={isAuth} />}
          />
          <Route
            path="candidates"
            element={<PrivateRoute element={<Candidates />} isAuth={isAuth} />}
          />
          <Route path="*" element={<Navigate to="/error" />} />
        </Routes>

        <Outlet />
      </div>
    </section>
  );
};
